import { useMemo, useState } from 'react';
import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button } from '@dagens/carrot';
import { GetOrganizationFoundResponse } from '../../../../api';
import { BottomActions } from '../../../../components/bottom-actions';
import { isValidEan } from '../../../../utils/billingAccount';
import { DeliveryInformationForm } from '../../../../components/delivery-information-form';
import { DeliveryWindow } from '../../../../types/Consumer';
import { CommonNavbar } from '@components/navbar';
import { Page } from '@components/page';
import PageHeader from '@components/page-header/PageHeader';

type LocationState =
  | {
      organization: GetOrganizationFoundResponse;
      billingAccountName: string;
      billingDepartmentName: string;
      billingEanNumber: string;
      billingPrimaryInvoiceEmail: string;
      billingReminderInvoiceEmail: string;
      contactInformationCustomerName: string;
      contactInformationContactPersonFullName: string;
      contactInformationContactPhone: string;
      contactInformationNotificationEmail: string;
    }
  | undefined;

const ReviewDeliveryInformation = () => {
  const [deliveryAddress, setDeliveryAddress] = useState<string>('');
  const [deliveryWindow, setDeliveryWindow] = useState<DeliveryWindow>({
    start: '08:00',
    end: '16:00'
  });
  const [deliveryInstructions, setDeliveryInstructions] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;
  const {
    organization,
    billingAccountName,
    billingDepartmentName,
    billingEanNumber,
    billingPrimaryInvoiceEmail,
    billingReminderInvoiceEmail,
    contactInformationCustomerName,
    contactInformationContactPersonFullName,
    contactInformationContactPhone,
    contactInformationNotificationEmail
  } = state || {};

  if (
    !organization ||
    !billingAccountName ||
    (organization.country === 'dk' && !isValidEan(billingEanNumber)) ||
    !billingPrimaryInvoiceEmail ||
    !billingReminderInvoiceEmail ||
    !contactInformationCustomerName ||
    !contactInformationContactPersonFullName ||
    !contactInformationContactPhone ||
    !contactInformationNotificationEmail
  ) {
    return <Navigate to="/customers/add/start" />;
  }

  const canContinue = useMemo(() => {
    return deliveryAddress && deliveryWindow && !hasError;
  }, [deliveryAddress, deliveryWindow, hasError]);

  return (
    <Page
      header={
        <>
          <CommonNavbar />
          <Box.FullWidth>
            <PageHeader
              headerText={t(
                'producer:AddCustomer.reviewDeliveryInformationPageHeader'
              )}
              annotation={`${t('producer:AddCustomer.headerAnnotation')} (4/4)`}
            />
            <Typography>
              {t(
                'producer:AddCustomer.reviewDeliveryInformationPageDescription'
              )}
            </Typography>
          </Box.FullWidth>
        </>
      }
      bottom={
        <BottomActions border>
          <Button
            size="regular"
            variant="borderless"
            onClick={() => {
              navigate('/customers');
            }}
          >
            <Typography variant="button" color="error">
              {t('common:cancel')}
            </Typography>
          </Button>
          {canContinue ? (
            // <ButtonLink
            //   to="/customers/add/review-delivery-information"
            //   variant="primary"
            //   state={{
            //     organization,
            //     billingAccountName,
            //     billingDepartmentName,
            //     billingEanNumber,
            //     billingPrimaryInvoiceEmail,
            //     billingReminderInvoiceEmail,
            //     contactInformationCustomerName,
            //     contactInformationContactPersonFullName,
            //     contactInformationContactPhone,
            //     contactInformationNotificationEmail
            //   }}
            //   color="secondary"
            // >
            //   {t('common:NextWithArrow')}
            // </ButtonLink>
            <Button
              size="fullWidth"
              variant="primary"
              onClick={() => {
                console.log({
                  organization,
                  billingAccountName,
                  billingDepartmentName,
                  billingEanNumber,
                  billingPrimaryInvoiceEmail,
                  billingReminderInvoiceEmail,
                  contactInformationCustomerName,
                  contactInformationContactPersonFullName,
                  contactInformationContactPhone,
                  contactInformationNotificationEmail,
                  deliveryAddress,
                  deliveryWindow,
                  deliveryInstructions
                });
              }}
            >
              {t(
                'producer:AddCustomer.reviewDeliveryInformationButtonCreateCustomerText'
              )}
            </Button>
          ) : (
            <Button size="small" variant="primary" disabled>
              {t(
                'producer:AddCustomer.reviewDeliveryInformationButtonCreateCustomerText'
              )}
            </Button>
          )}
        </BottomActions>
      }
    >
      <DeliveryInformationForm
        deliveryAddress={deliveryAddress}
        onDeliveryAddressChange={(address: string) => {
          setDeliveryAddress(address);
        }}
        deliveryWindow={deliveryWindow}
        earliestDeliveryWindowHour={7}
        latestDeliveryWindowHour={17}
        minDeliveryWindowDuration={2}
        onDeliveryWindowChange={(deliveryWindow: DeliveryWindow) => {
          setDeliveryWindow(deliveryWindow);
        }}
        deliveryInstructions={deliveryInstructions}
        onDeliveryInstructionsChange={(instructions: string) => {
          setDeliveryInstructions(instructions);
        }}
        onErrorChange={(hasError: boolean) => {
          setHasError(hasError);
        }}
      />
    </Page>
  );
};

export default ReviewDeliveryInformation;
