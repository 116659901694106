import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@dagens/frontend-i18n';
import { Typography } from '@dagensmat/carrot/Components';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Form, Icon } from '@dagens/carrot';
import { GetOrganizationFoundResponse } from '../../../../api';
import { BottomActions } from '../../../../components/bottom-actions';
import { ButtonLink } from '../../../../_common/components/button/Button.style';
import { FormFieldType } from '../../../../carrot/form-field/types';
import { FormField } from '../../../../carrot/form-field';
import { isValidEan } from '../../../../utils/billingAccount';
import { validateEmail } from '../../../../utils/validation';
import useFormErrors from '../../../../utils/use-form-errors';
import { CommonNavbar } from '@components/navbar';
import { Page } from '@components/page';
import PageHeader from '@components/page-header/PageHeader';

type LocationState =
  | {
      organization: GetOrganizationFoundResponse;
      billingAccountName: string;
      billingDepartmentName: string;
      billingEanNumber: string;
      billingPrimaryInvoiceEmail: string;
      billingReminderInvoiceEmail: string;
    }
  | undefined;

const ReviewContactInformation = () => {
  const [contactInformationCustomerName, setContactInformationCustomerName] =
    useState<string>('');
  const [
    contactInformationContactPersonFullName,
    setContactInformationContactPersonFullName
  ] = useState<string>('');
  const [contactInformationContactPhone, setContactInformationContactPhone] =
    useState<string>('');
  const [
    contactInformationNotificationEmail,
    setContactInformationNotificationEmail
  ] = useState<string>('');
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { setFieldError, hasFieldError, hasAnyErrors } = useFormErrors();
  const state = location.state as LocationState;
  const {
    organization,
    billingAccountName,
    billingDepartmentName,
    billingEanNumber,
    billingPrimaryInvoiceEmail,
    billingReminderInvoiceEmail
  } = state || {};

  if (
    !organization ||
    !billingAccountName ||
    (organization.country === 'dk' && !isValidEan(billingEanNumber)) ||
    !billingPrimaryInvoiceEmail ||
    !billingReminderInvoiceEmail
  ) {
    return <Navigate to="/customers/add/start" />;
  }

  useEffect(() => {
    setContactInformationCustomerName(organization.name);
  }, [organization]);

  const canContinue = useMemo(() => {
    return (
      contactInformationCustomerName &&
      contactInformationContactPersonFullName &&
      contactInformationContactPhone &&
      contactInformationNotificationEmail &&
      !hasAnyErrors()
    );
  }, [
    contactInformationCustomerName,
    contactInformationContactPersonFullName,
    contactInformationContactPhone,
    contactInformationNotificationEmail
  ]);

  return (
    <Page
      header={
        <>
          <CommonNavbar />
          <Box.FullWidth>
            <PageHeader
              headerText={t(
                'producer:AddCustomer.reviewContactInformationPageHeader'
              )}
              annotation={`${t('producer:AddCustomer.headerAnnotation')} (3/4)`}
            />
            <Typography>
              {t(
                'producer:AddCustomer.reviewContactInformationPageDescription'
              )}
            </Typography>
          </Box.FullWidth>
        </>
      }
      bottom={
        <BottomActions border>
          <Button
            size="regular"
            variant="borderless"
            onClick={() => {
              navigate('/customers');
            }}
          >
            <Typography variant="button" color="error">
              {t('common:cancel')}
            </Typography>
          </Button>
          {canContinue ? (
            <ButtonLink
              to="/customers/add/review-delivery-information"
              variant="primary"
              state={{
                organization,
                billingAccountName,
                billingDepartmentName,
                billingEanNumber,
                billingPrimaryInvoiceEmail,
                billingReminderInvoiceEmail,
                contactInformationCustomerName,
                contactInformationContactPersonFullName,
                contactInformationContactPhone,
                contactInformationNotificationEmail
              }}
              color="secondary"
            >
              {t('common:NextWithArrow')}
            </ButtonLink>
          ) : (
            <Button variant="primary" size="small" disabled>
              {t('common:NextWithArrow')}
            </Button>
          )}
        </BottomActions>
      }
    >
      <Form>
        <FormField
          type={FormFieldType.TEXT}
          label={t(
            'producer:AddCustomer.reviewContactInformationFieldCustomerNameLabel'
          )}
          helpText={t(
            'producer:AddCustomer:reviewContactInformationFieldCustomerNameDescription'
          )}
          feedback={
            <>
              <Icon color="warning" icon="alert" />
              {t('producer:AddCustomer:retrievedFromCvrRegistry')}
            </>
          }
          inputProps={{
            value: contactInformationCustomerName,
            onChange: ({ target: { value } }) => {
              setFieldError('contactInformationCustomerName', !value);
              return setContactInformationCustomerName(value);
            },
            invalid: hasFieldError('contactInformationCustomerName')
          }}
          showError={hasFieldError('contactInformationCustomerName')}
        />
        <FormField
          type={FormFieldType.TEXT}
          label={t(
            'producer:AddCustomer.reviewContactInformationFieldContactPersonFullNameLabel'
          )}
          inputProps={{
            value: contactInformationContactPersonFullName,
            onChange: ({ target: { value } }) => {
              setFieldError('contactInformationContactPersonFullName', !value);
              return setContactInformationContactPersonFullName(value);
            },
            invalid: hasFieldError('contactInformationContactPersonFullName')
          }}
          showError={hasFieldError('contactInformationContactPersonFullName')}
        />
        <FormField
          label={t(
            'producer:AddCustomer.reviewContactInformationFieldContactPersonPhoneLabel'
          )}
          inputProps={{
            value: contactInformationContactPhone,
            onChange: ({ target: { value } }) => {
              setFieldError('contactInformationContactPhone', !value);
              setContactInformationContactPhone(value);
            }
          }}
          showError={hasFieldError('contactInformationContactPhone')}
          type={FormFieldType.TEXT}
        />
        <FormField
          label={t(
            'producer:AddCustomer.reviewContactInformationFieldNotificationEmailLabel'
          )}
          helpText={t(
            'producer:AddCustomer:reviewContactInformationFieldNotificationEmailDescription'
          )}
          inputProps={{
            value: contactInformationNotificationEmail,
            onChange: ({ target: { value } }) => {
              setFieldError(
                'contactInformationNotificationEmail',
                !validateEmail(value)
              );
              setContactInformationNotificationEmail(value);
            },
            invalid: hasFieldError('contactInformationNotificationEmail')
          }}
          showError={hasFieldError('contactInformationNotificationEmail')}
          type={FormFieldType.TEXT}
        />
      </Form>
    </Page>
  );
};

export default ReviewContactInformation;
